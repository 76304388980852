<template>
    <button @click="handleClick">
        <template v-if="title">{{ title }}</template>
        <slot></slot>
    </button>
</template>

<script>
import {inject} from 'vue';

export default {
    created() {
        this.goToPage = inject('goToPage');
    },
    props: {
        title: String,
        url: {type: String, default: null},
        pageId: {type: String, default: null}
    },
    methods: {
        handleClick() {
            if (this.url) {
                window.open(this.url, '_blank');
            } else if (this.pageId) {
                this.goToPage(this.pageId);
            }
        }
    }
}
</script>