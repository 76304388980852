import survey from "@/survey.js";

function validateTelegramSupport() {
    // TODO uncomment

    // if(!(window?.Telegram?.WebApp?.initData)) {
    //     alert('Unsupported platform!');
    //     console.error('unsupported platform!');
    //     return false;
    // }

    return true;
}

async function submitSurvey() {
    if(!validateTelegramSupport()) {
        return null;
    }

    //TODO implement
    console.log(survey.serialize());
}

export default {
    submitSurvey
};