import MainPage from "./pages/MainPage.vue";
import AboutAlphaLabs from "./pages/AboutAlphaLabs.vue";
import AlphaGoatIntro from "./pages/AlphaGoatIntro.vue";
import BecomeContrib from "./pages/BecomeContrib.vue";
import RegRoundSim1 from "./pages/RegRoundSim1.vue";
import GetAlphaGoat from "./pages/GetAlphaGoat.vue";
import RegRoundSim2 from "./pages/RegRoundSim2.vue";

const pages = {
    MainPage, 
    AboutAlphaLabs, 
    AlphaGoatIntro, 
    GetAlphaGoat, 
    BecomeContrib, 
    RegRoundSim1,
    RegRoundSim2
};

export default {
    getEntryPage() {
        return MainPage;
    },
    registerComponents(components) {
        for(let key in pages) {
            components[key] = pages[key];
        }
    },
};