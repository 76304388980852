<template>
    <div class="anchor-bottom"><slot></slot></div>
</template>

<style scoped>
.anchor-bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #f8f9fa;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center baseline;
  gap: 10px;
}
</style>