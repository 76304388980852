import { createApp } from 'vue'
import App from '@/App.vue'

import BaseButton from '@/components/BaseButton.vue';
import ButtonLayout from '@/components/ButtonLayout.vue';

const app = createApp(App);

const components = {BaseButton, ButtonLayout};

for(let key in components) {
    app.component(key, components[key]);
}

app.mount('#app')
