const MAX_QUESTIONS = 64;

var currentData = {
    questions: {},
    wallet: null
};

var localStorage = window?.localStorage;
if(!localStorage) {
    console.error('local storage is not supported.');
    localStorage = {
        setItem(k, v) { this[k] = v; },
        getItem(k) { return this[k] ?? null; }
    };
}

/**
 * @param {number} questionId 
 * @param {number} answerId 
 */
function answer(questionId, answerId) {
    if (questionId < 0 || questionId > 255) {
        alert('question id out of range:', questionId);
        return;
    }

    if (answerId < 0 || answerId > 15) {
        alert('answer id out of range:', answerId);
        return;
    }

    let id = questionId.toString(16).padStart(2, '0');
    currentData.questions[id] = answerId.toString(16);
    saveLocal();
}

/**
 * @param {string} wallet 
 */
function setWallet(wallet) {
    currentData.wallet = wallet;
    saveLocal();
}

/**
 * @param {number} questionId 
 */
function isAnswered(questionId) {
    let id = questionId.toString(16).padStart(2, '0');
    return id in currentData.questions;
}

/**
 * @param {number} questionId 
 */
function getAnswer(questionId) {
    let id = questionId.toString(16).padStart(2, '0');
    if (id in currentData.questions) {
        return parseInt(currentData.questions[id], 16);
    }

    return null;
}

function getAnsweredQuestionIds() {
    let result = [];
    for(let id in currentData.questions) {
        result.push(parseInt(id, 16));
    }
    return result;
}

function saveLocal() {
    localStorage.setItem('survey', serialize(currentData));
}

function loadLocal() {
    let dataStr = localStorage.getItem('survey');
    if(dataStr) {
        let data = deserialize(dataStr);
        if(data) {
            currentData = data;
        }
    }
}

function serialize(data) {
    let questionData = '';
    let count = 0;
    for(let key in data.questions) {
        if(++count > MAX_QUESTIONS) {
            console.error('too many questions to serialize! ignoring the rest.');
            break;
        }
        questionData += `${key}${data.questions[key]}`;
    }

    return JSON.stringify({
        w: data.wallet, 
        qd: questionData,
        h: hashCode(data.wallet+questionData)
    });
}

function deserialize(dataStr) {
    let obj = JSON.parse(dataStr);

    if (obj.h != hashCode(obj.w + obj.qd)) {
        console.error('invalid serialized data');
        return null;
    }

    let data = {
        wallet: obj.w,
        questions: {}
    };

    // data structure: [question ID: XX][answer ID: X] (example: 0A2)
    for(let i=0;i<obj.qd.length;i+=3) {
        let questionId = obj.qd.substring(i, i+2);
        let answerId = obj.qd[i+2];
        data.questions[questionId] = answerId;
    }

    return data;
}

function hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // convert to 32bit integer
    }
    return hash;
}

loadLocal();

export default {
    answer, setWallet, isAnswered, getAnswer, getAnsweredQuestionIds, 
    serialize() {
        return serialize(currentData);
    }
};
