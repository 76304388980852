<template>
    <h1>[More Perks]</h1>
    <p>[Content]</p>
    <ButtonLayout>
        <button @click="submitSurvey">Test</button>
        <BaseButton title="Back" page-id="MainPage" />
    </ButtonLayout>
</template>

<script>
// import survey from '@/survey.js';
import backend from '@/backend.js';

export default {
    methods: {
        async submitSurvey() {
            // survey.answer(1, 1);
            // survey.answer(2, 1);
            // survey.answer(3, 4);
            // survey.answer(12, 2);
            // survey.setWallet('testwallet');

            await backend.submitSurvey();
            console.log('done!');
        }
    }
}
</script>